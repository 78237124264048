@font-face {
  font-family: 'GTWalsheim';
  src: local('GTWalsheim'), url('../src/assets/fonts/gt-walsheim-medium.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'GTWalsheim_Bold';
  src: local('GTWalsheim'), url('../src/assets/fonts/gt-walsheim-bold.ttf') format('truetype');
  font-display: fallback;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  margin: 0;
}

html {
  font-size: 16px;
  font-family: 'GTWalsheim';
  background: rgb(17,28,78);
  background: radial-gradient(circle, rgba(17,28,78,1) 29%, rgba(0,0,0,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Toastify__toast--success {
  background: black !important;
}
.Toastify__progress-bar--success {
  background-color: #07bc0c !important;
}
.Toastify__toast--error {
  background: black !important;
}
.Toastify__progress-bar--error {
  background-color: #e74c3c !important;
}
